<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-18 11:55:20
 * @LastEditTime: 2020-04-24 18:52:39
 -->
<template>
	<div class="app-container signList" v-loading="loading">
		<el-form inline :model="searchForm" class="searchForm" size="small">
			<el-form-item label="培训课程">
				<el-select placeholder="请选择培训课程" filterable size="small" v-model="searchForm.trainingPlanId" @change="_selectPlanId">
					<el-option v-for="item in list2" :key="item.id" :value="item.id" :label="item.trainName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="考核状态">
				<el-select placeholder="请选择考核状态" filterable clearable size="small" v-model="searchForm.inspectionStatus">
					<el-option value="1" label="待考核"></el-option>
					<el-option value="2" label="已考核"></el-option>
					<el-option value="3" label="已公布"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="报名时间">
				<el-date-picker
					v-model="searchForm.signUpStartDate"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [00:00:00]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.signUpEndDate"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [23:59:59]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<div class="fr" style="margin: 5px 0 10px">
				<el-button type="primary" size="small" style="margin-left: 20px" @click="_getList(1)" icon="el-icon-search">查询</el-button>
				<el-button
					type="primary"
					size="small"
					@click="dialogImportVisible = true"
					icon="el-icon-upload2"
					v-if="permissionControlBtns(pageName, 'Import')"
					>导入</el-button
				>
				<el-button type="primary" size="small" @click="_export" icon="el-icon-download" v-if="permissionControlBtns(pageName, 'Export')"
					>导出</el-button
				>
				<el-button
					type="primary"
					size="small"
					@click="_results"
					icon="el-icon-c-scale-to-original"
					v-if="permissionControlBtns(pageName, 'AppraisalResults')"
					>公布考核结果</el-button
				>
			</div>
		</el-form>
		<el-table :data="list" stripe @selection-change="_handleSelectionChange">
			<el-table-column align="center" type="selection" width="55"></el-table-column>
			<el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" :min-width="col.minWidth" align="center" :label="col.label">
				<template v-slot="{ row }" v-if="col.prop == 'workNumber'">
					<div>{{ row[col.prop] == 0 ? '-' : row[col.prop] }}</div>
				</template>
				<template v-slot="{ row }" v-else-if="col.prop === 'accessEvaluate'">
					<div class="coursePlace">
						{{ row.accessEvaluate && row.accessEvaluate.length > 60 ? row.accessEvaluate.substring(0, 53) + '...' : row.accessEvaluate }}
					</div>
				</template>
				<template v-slot="{ row }" v-else-if="col.prop == 'inspectionStatus'">
					<el-tag :type="row.inspectionStatus == '待考核' ? 'danger' : row.inspectionStatus == '已考核' ? 'warning' : 'success'">{{
						row.inspectionStatus
					}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="center" width="130px" label="操作" fixed="right">
				<template v-slot="{ row }">
					<el-row>
						<el-button type="text" @click="_rowEdit(row)" v-if="row.inspectionStatus != '已公布' && _returnSignStatus(row)"
							>编辑</el-button
						>
						<el-button type="text" @click="_rowEdit(row, true)">详情</el-button>
					</el-row>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="margin: 20px 0; text-align: right"
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 50, 100, 200]"
			:page-size="searchForm.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.total"
		></el-pagination>
		<ImportCom
			:dialogImportVisible.sync="dialogImportVisible"
			:importUrl="importUrl"
			:params="searchForm"
			:type="'train'"
			@handleSearch="_getList(searchForm.pageNo)"
		/>
		<el-dialog center :title="reform.trainingCourse" :visible.sync="dialogReVisible" class="loser" width="45%">
			<div class="losetitle">
				<strong>{{ reform.staffName }}{{ `(${reform.workNumber == 0 ? '-' : reform.workNumber})` }}</strong>
				<div>
					<span>{{ reform.departmentName }} {{ reform.jobName }}</span>
				</div>
			</div>
			<p class="border-line"></p>
			<el-form ref="reform" :rules="rules" :model="reform" label-width="100px">
				<el-form-item label="考核结果" prop="accessScore">
					<el-input style="width: 45%" :disabled="detailReadOnly" placeholder="请输入考核结果" v-model="reform.accessScore"></el-input>
				</el-form-item>
				<el-form-item label="考核评价" prop="accessEvaluate">
					<el-input
						:disabled="detailReadOnly"
						type="textarea"
						autosize
						placeholder="请输入考核评价"
						v-model="reform.accessEvaluate"
						maxlength="500"
						show-word-limit
					></el-input>
				</el-form-item>
			</el-form>
			<div class="buttonDiv" v-if="!detailReadOnly">
				<el-button @click="dialogReVisible = false">取消</el-button>
				<el-button type="primary" @click="_signUpdate" :loading="btnLoading">保存</el-button>
				<el-button type="primary" @click="_signUpResults" :loading="btnLoading">公布结果</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { signUpList, results, signUpDetails, signUpdate, trainingName } from '@/api/trainMange.js';
import ImportCom from '@/components/import';
export default {
	computed: {
		pageName() {
			return this.$route.name;
		}
	},
	components: { ImportCom },
	data() {
		return {
			loading: false,
			btnLoading: false,
			dialogImportVisible: false,
			dialogReVisible: false,
			detailReadOnly: false,
			dateRange: '',
			dateRange2: '',
			list: [],
			list2: [],
			multipleSelection: [],
			currentRow: {},
			importUrl: '/v1/education/signUp/import',
			searchForm: {
				inspectionStatus: '',
				signUpStartDate: '',
				signUpEndDate: '',
				trainingPlanId: '',
				pageNo: 1,
				pageSize: 20,
				total: 0
			},
			reform: {
				id: '',
				accessScore: '',
				accessEvaluate: '',
				trainingCourse: ''
			},
			columns: [
				{ label: '培训课程', prop: 'trainingName' },
				{ label: '用户名', prop: 'staffName' },
				{ label: '工号', prop: 'workNumber' },
				{ label: '部门', prop: 'departmentName', minWidth: 125 },
				{ label: '岗位', prop: 'jobName', minWidth: 125 },
				{ label: '考核状态', prop: 'inspectionStatus' },
				{ label: '考核结果', prop: 'accessScore' },
				{ label: '考核评价', prop: 'accessEvaluate', minWidth: 200 },
				{ label: '报名时间', prop: 'signUpDate', minWidth: 145 }
			],
			rules: {
				accessScore: [{ required: true, message: '请输入考核结果', trigger: 'blur' }]
			}
		};
	},
	methods: {
		_handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			signUpList(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res.list;
				this.multipleSelection = [];
				this.searchForm.total = res.totalNum;
			});
		},
		_selectPlanId(val) {
			localStorage.setItem('selectPlanId', val);
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/education/signUp/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		_returnStatu(time, item) {
			if (item.value == 1) {
				return new Date(time).getTime() > new Date().getTime();
			}
			return true;
		},
		_results() {
			if (this.multipleSelection.length <= 0) {
				this.$message.error('请先选择要公布的记录');
				return false;
			}
			const _status = this.multipleSelection.find((value) => value.inspectionStatus == '已公布' || value.inspectionStatus == '待考核');

			if (_status != undefined) {
				this.$message.error('公布结果的记录只能为已考核的记录');
				return false;
			}
			const _obj = this.list2.find((value) => value.id == this.searchForm.trainingPlanId);

			this.$confirm(`您确认公布${_obj ? _obj.trainName : ''}已选人员考核结果吗？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const _ids = [];

					this.multipleSelection.forEach((item) => _ids.push(item.id));
					this.loading = true;
					results({ ids: _ids }).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.$message.success('公布成功');
							this._getList();
						}
					});
				})
				.catch(() => {});
		},
		_signUpdate() {
			this.$refs.reform.validate((valid) => {
				if (valid) {
					this.btnLoading = true;
					const _obj = { ...this.reform };

					_obj.status = 1;
					_obj.id = this.currentRow.id;
					signUpdate(_obj).then((res) => {
						this.btnLoading = false;
						if (res.code == 0) {
							this.$message.success('保存成功');
							this._getList();
							this.dialogReVisible = false;
						}
					});
				}
			});
		},
		_signUpResults() {
			this.$refs.reform.validate((valid) => {
				if (valid) {
					this.$confirm(`您确认公布${this.reform.staffName}在${this.reform.trainingCourse}的考核结果吗？`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
						.then(() => {
							this.btnLoading = true;
							const _obj = { ...this.reform };

							_obj.status = 2;
							_obj.id = this.currentRow.id;
							signUpdate(_obj).then((res) => {
								this.btnLoading = false;
								if (res.code == 0) {
									this.$message.success('公布成功');
									this._getList();
									this.dialogReVisible = false;
								}
							});
						})
						.catch(() => {});
				}
			});
		},
		_rowEdit(row, type) {
			this.detailReadOnly = type || false;
			this.currentRow = row;
			signUpDetails({ id: row.id }).then((res) => {
				this.reform = res;
				this.dialogReVisible = true;
				this.$nextTick(() => {
					this.$refs.reform && this.$refs.reform.clearValidate();
				});
				this.$forceUpdate();
			});
		},
		_getNameList() {
			trainingName({}).then((res) => {
				this.list2 = res;
				this.searchForm.trainingPlanId =
					Number(this.$route.query.planId) || Number(localStorage.getItem('selectPlanId')) || Number(this.list2[0].id);
				this._getList();
				this.$forceUpdate();
			});
		},
		_returnSignStatus(row) {
			if (new Date(row.trainingStartTime).getTime() <= new Date().getTime()) {
				return true;
			}
			return false;
		}
	},
	mounted() {
		this._getNameList();
	}
};
</script>
<style scoped lang="scss">
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.searchForm {
	overflow: hidden;
	margin: 10px 0 20px;
}
.coursePlace {
	max-height: 90px;
	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
}
.loser {
	.losetitle {
		margin: 0 20px;
		padding-bottom: 20px;
		// border-bottom: 1px dashed #ccc;
		strong {
			font-size: 18px;
		}
		div {
			margin-top: 10px;
			line-height: 20px;
		}
	}
	.border-line {
		width: 93%;
		margin: 0 20px 20px;
		height: 1px;
		background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
		background-size: 10px 1px;
		background-repeat: repeat-x;
	}
	.loseType,
	.reason {
		margin-left: 20px;
		.el-radio-group {
			margin: 20px 0;
		}
	}
	.reason {
		.el-radio {
			min-width: 16%;
			height: 25px;
		}
	}
	.buttonDiv {
		text-align: center;
		margin-top: 20px;
	}
}
</style>
